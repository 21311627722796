<template>
  <div class="desktopLayoutClass">



    <v-app>


    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Unauthorized")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                 {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>


    <div>
      <v-row justify="center">
        <v-dialog v-model="error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Something_Went_Wrong")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogError">
              {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="requiredFields" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Please_Rate_Us_Before_Submitting")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="requiredFields = false">
                  {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>


     	<div v-show="show_header" style="display: flex; flex-direction: row; padding-top: 10px;padding-left: 5px;margin: -1px; background-color:white; " class="header">
	          <div style="display: flex; flex-direction: row; width: 100%" >
               <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage(booking_id)"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
	            <div style="width: 90%" align="left">
	                <span style="font-weight: 600;font-size: 15pt; ">{{ $t("Customer.Feedback.Share_Your_Feedback") }}</span>
	            </div>
	          </div>
	        </div>
      <v-container >
        <br />
      <div>
        <p style="font-weight:700; color:#1467BF; text-align:left">{{ $t("Customer.Feedback.Thank_You_Use") }}</p>
      </div>
      <div>
        <p style="font-weight:700; text-align:left">{{ $t("Customer.Feedback.Recommend_Question") }}</p>
      </div>
        <!-- <span style="text-align: Left; padding: -1px; margin-top: -4px">{{ $t("Customer.Feedback.Rate_Your_Call") }}</span> -->
        <br />
        <!-- <v-rating
          v-model="rating"
          hover
          length="11"
          size="30"
          class="starClass"
          value="3"
          x-large
          >
            <template v-slot:item="props">
              <v-icon
                :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                @click="props.click"
                v-text = "`mdi-numeric-${props.index}-box`"
              >
                {{ props.isFilled ? 'mdi-square' : 'mdi-square-outline'}}
              </v-icon>
            </template>
        </v-rating> -->
        <div style="display:flex; flex-direction:row; justify-content:space-around;">
          <div v-for="(item,index) in colors1" :key="index">
            <v-img v-if="color_limit+1>index && color_flag" style="height:32px; width:32px; border-radius:10px; cursor: pointer;" @click="range_color_rating(item.val)" :src="item.src"></v-img>
            <v-img v-else style="height:32px; width:32px; border-radius:10px; cursor: pointer;" @click="range_color_rating(item.val)" :src="item.grey_src"></v-img>
          </div>
        </div>
        <!-- <img src="@/assets/ratings.jpg" width="100%"> -->
        <img style="padding-top:10px;" v-if="$i18n.locale == 'mm'" src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/Feedback_Burmese.svg" width="100%">
        <img style="padding-top:10px;" v-else-if="$i18n.locale == 'en'" src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/Feedback_Eng.svg" width="100%">
        <div>
          <br />
          <div align="left">
            <span style="text-align: Left; padding: 0px"
              >{{ $t("Customer.Feedback.Share") }}</span
            >
          </div>
          <v-textarea
            outlined
            v-model="comment"
            height="120"
            :placeholder="translation_description"
            :rules="[
              (v) =>
                (v || '').length < 160 ||
                'Comment must be 160 characters or less',
            ]"
            style="padding: 20px;"
            background-color="#F4F6FA"
          ></v-textarea>
        </div>
        <div style="display:flex; flex-direction:column; justify-content: space-evenly; margin-top:auto">
          <div style="align-self: felx-end">
        <v-btn
          :disabled ="!submitfb"
          @click="giveFeedback"
          width="90%"
          color="primary"
          style="height: 44px;align-self: felx-end"
          class="submitutton"
          >{{ $t("Customer.Feedback.Submit") }}</v-btn
        >
        </div>
        <div style="align-self: felx-end">
        <v-btn
        width="90%"
          @click="homePage"
          color="primary"
          outlined
          elevation="5"
          style="height: 44px; margin-top:10px"
          > <span style="color: black">{{ $t("Customer.Feedback.Do_It_Later") }}</span></v-btn
        >
        </div>
        </div>
        <v-dialog persistent v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="text-h5">{{ $t("Customer.Feedback.Thank_You") }}</v-card-title>
            <v-card-text>{{ $t("Customer.Feedback.Submit_Success") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="homePage()">  {{$t("Warning_Message.Ok")}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
export default {
  name: "CustomerHome",
  components: {
    NavBar,
  },
  data() {
    return {
      colors: ['#D43634', '#E46646', '#DF7D49', '#E1904F', '#E59851','#E9A657', '#E8B355', '#EAD227', '#CBCD60', '#94EF66', '#60A866'],
      colors1: [{ src:"https://s3iconimages.mymedicine.com.mm/colored_0.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_0.svg",
                  val:0,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_1.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_1.svg",
                  val:1,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_2.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_2.svg",
                  val:2,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_3.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_3.svg",
                  val:3,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_4.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_4.svg",
                  val:4,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_5.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_5.svg",
                  val:5,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_6.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_6.svg",
                  val:6,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_7.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_7.svg",
                  val:7,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_8.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_8.svg",
                  val:8,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_9.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_9.svg",
                  val:9,
                },
                { src:"https://s3iconimages.mymedicine.com.mm/colored_10.svg",
                  grey_src:"https://s3iconimages.mymedicine.com.mm/grey_10.svg",
                  val:10,
                },
              ],
      auth_error_enable: false,
      color_flag:false,
      error_enable: false,
      booking_id: "",
      rating: null,
      comment: "",
      requiredFields: false,
      feedback_submitted: false,
      isDisabled: false,
      dialog: false,
      appointment_reference_number: "",
      appointment_details: "",
      currentCustomer : "",
      customer_id : "",
      customer_name : "",
      isMobile : false,
      translation_description: "Type your description here",
      show_header: true,
      submitfb : false,
      color_limit:0,
    };
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    //document.title = "Share your Feedback"
    if(this.$store.state.locale == "en")
      document.title = "Share Your Feedback"
    else
      document.title = "သင်၏သုံးသပ်ချက်ကို ဝေမျှမည်"
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
		var access = this.$cookies.get("access");
    if(!this.currentCustomer){
      this.$router.push({
        name : "Login"
      })
    }
    else{

      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "FeedbackScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
      //logging page hit - end
        var params = this.$route.params;
          var booking_id = params["booking_id"];
          this.booking_id = booking_id;
        var viewParticularAppointmentPageData = {
        "token" : this.currentCustomer,
        "bookingId" : this.booking_id,
        "typeOfUser" : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/feedbackPage", viewParticularAppointmentPageData)
      .then((viewParticularAppointmentPageResponse) => {
        this.customer_id = viewParticularAppointmentPageResponse.data.customer._id;
        this.customer_name = viewParticularAppointmentPageResponse.data.customer.customer_name;
        this.phoneNumber = viewParticularAppointmentPageResponse.data.customer.phoneNumber;
        this.appointment_reference_number =viewParticularAppointmentPageResponse.data.data.appointment_reference_number;
        this.appointment_details = viewParticularAppointmentPageResponse.data.data;
        let submit_stat = viewParticularAppointmentPageResponse.data.submit_stat;

        if(this.$i18n.locale == 'en')
        {
          this.translation_description = 'Type your description here';
        }
        else if(this.$i18n.locale == 'mm')
        {
          this.translation_description = 'အကြောင်းအရာကို ရေးပါ။';
        }
        this.submitfb = true;
        if(submit_stat == "SUBMITTED")
        { this.redirectToMyBookingPage()
          //redirect to my bookings page
        }
        this.userPageViewTable();
      })
      .catch((viewParticularAppointmentPageError)=>{
         this.submitfb = true;
        if(viewParticularAppointmentPageError.response.status == 401){
          this.auth_error_enable = true;
        }
        else{
          this.error_enable = true;
        }
      });
    }
    //check if feedback already provided
  },
  methods: {
     genColor (i) {
      return this.colors[i]

    },
    range_color_rating(i){
      this.color_flag=true;
      this.color_limit = i;
      this.rating = this.color_limit;
    },

    redirectToMyBookingPage(){
      this.$router.push({
         path: '/customer/myBookings/'
      });
    },
    redirectToHomePage(booking_id){
      this.$router.go(-1);
    },
    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },
    giveFeedback() {
      // console.log(this.rating)
      if(this.rating != null)
      {
        this.submitfb = false;
        var feedback_body = {
          'bookingId' : this.booking_id,
          'rating': this.rating,
          'feedback': this.comment,
          'token': this.currentCustomer,
          'typeOfUser':"CUSTOMER",
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/insertFeedback",feedback_body)
        .then((giveFeedbackResponse) => {
          this.dialog = true;
        })
        .catch((giveFeedbackError) => {
          this.requiredFields = true;
          this.submitfb = true;
        });
      }
      else
      {
        this.requiredFields = true;
      }
    },
    homePage() {
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'feedbackPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'feedbackPage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
};
</script>
<style>
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
}
.starClass {
  display:flex;
  flex-direction: row;
  border: transparent;
  border-radius: 8px;
  justify-content: space-between;

  /* margin-top: -10px; */
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.commentBox {
  border-radius: 12px !important;
  padding-left: 10px !important;
}
.submitutton {
  border-radius: 8px;
  margin-top: 25px;
  height: 38px !important;
}
.doLaterButton {
  font-weight: 200;
}
.starClass.v-rating .v-icon {
    padding: unset;
    margin-left:-6px;
    user-select: none;
    outline: none;
}
.mdi:before, .mdi-set {
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 150%;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
}

/* @media (min-width: 800px){
  .feedBackClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>